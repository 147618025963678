






import { type PropType, defineComponent } from "@vue/composition-api";
import type { PaginatedActivityLogViewModel } from "@/lib/backend/core.api";
import LogCallAttempt from "./variants/CallAttempt.vue";
import LogDefault from "./variants/Default.vue";
import LogNotificationReceived from "./variants/NotificationReceived.vue";

export default defineComponent({
	components: { LogCallAttempt, LogDefault, LogNotificationReceived },
	props: {
		log: {
			type: Object as PropType<PaginatedActivityLogViewModel["data"][number]>,
			required: true,
		},
	},
});
